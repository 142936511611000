/* Login css */
.login-container {
    display: flex;
    width: 100%;
    max-width: 900px;
    height: 600px; /* Fixed height for consistency */
    margin: 50px auto;
    border: 1px solid #808080;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .login-form {
    width: 50%;
    padding: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login-banner {
    width: 50%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #FAFAFA;
  }
  
  .forgot-password {
    color: #707070;
    margin-top: 10px;
    display: inline-block;
    text-decoration: none;
  }
  
  .login-button {
    background-color: #f05628;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    width: 70%;
    height: 50px;
    margin-left: 15%;
    
  }
  
  .otp-login {
    margin-top: 15px;
    font-size: 0.9rem;
    margin-left: 25%;
  }
  
  .otp-login a {
    color: #ff5400;
    text-decoration: none;
  }
  
  .login-banner {
    width: 50%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rim-logo {
    max-width: 60%;
  }

  /* admin siginup */
  .adminsignup-form {
    width: 50%;
    padding: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .adminsignup-banner {
    width: 50%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .error-message {
    color: #f05628;
    margin-top: 10px;
    text-align: center;
  }

  /* Signup css */
  .signup-container {
    display: flex;
    width: 100%;
    max-width: 900px;
    height: 600px; /* Fixed height for consistency */
    margin: 50px auto;
    border: 1px solid #808080;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
  }
  
  .signup-form {
    width: 50%;
    padding: 50px;;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .signup-banner {
    width: 50%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .message {
    color: #f05628;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    height: 30px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #FAFAFA;
  }
  
  .signup-button {
    background-color: #f05628;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    width: 70%;
    height: 50px;
    margin-left: 15%;
  }
  
  .signup-link {
    margin-top: 15px;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .signup-link a {
    color: #ff5400;
    text-decoration: none;
  }
  
  .rim-logo {
    max-width: 60%;
  }

  @media (max-width: 912px) and (min-width: 768px){
    /* Login css */
    .login-container {
        width: 90% !important;
        height: auto !important;
        margin: 10% auto;
      }
    
      .login-form, 
      .login-banner {
        width: 100%;
        padding: 30px;
      }
    
      .login-banner {
        padding: 20px;
      }
    
    .login-button {
      width: 80%;
      margin-left: 10%;
    }
    
    /* Signup css */
    .signup-container {
        width: 90% !important;
        height: auto;
        margin: 20px auto;
      }
    
      .signup-form, 
      .signup-banner {
        width: 100%;
        padding: 30px;
      }
    
      .signup-banner {
        padding: 20px;
      }
      .signup-form{
        justify-content: center;
      }
      .signup-link {
        text-align: center;
        margin-left: 0;
      }
      .signup-button {
        width: 80%;
        margin-left: 10%;
      }
    }

    @media (max-width: 767px) and (min-width: 481px){
        /* Login css */
        .login-container {
            flex-direction: column;
            width: 90% !important;
            height: auto;
            margin: 20px auto;
          }
        
          .login-form, 
          .login-banner {
            width: 100%;
            padding: 30px;
          }
        
          .login-banner {
            order: -1;
            padding: 20px;
          }
        
        .login-button {
          width: 80%;
          margin-left: 10%;
        }

        /* admin signup */
        .adminsignup-container {
            flex-direction: column;
            width: 90%;
            height: auto;
            margin: 20px auto;
          }
        
          .adminsignup-form,
          .adminsignup-banner {
            width: 100%;
            padding: 30px;
          }
        
          .adminsignup-banner {
            order: -1;
            padding: 20px;
          }
          /* admin signup */
          .signup-container {
            flex-direction: column;
            width: 90%;
            height: auto;
            margin: 20px auto;
          }
        
          .signup-form, 
          .signup-banner {
            width: 100%;
            padding: 30px;
          }
          .signup-form{
            align-items: center !important;
          }
        
          .signup-banner {
            order: -1;
            padding: 20px;
          }
        
          .signup-button {
            width: 80%;
            margin-left: 10%;
          }
          .signup-link {
            font-size: 0.8rem;
            text-align: center;
            margin-left: 0;
          }
    }

    @media (max-width: 480px){
        /* Login css */
        .login-container {
            height: auto;
            border: none;
            box-shadow: none;
            flex-direction: column;
            align-items: center;
          }
        
          .login-form {
            padding: 20px;
            width: 100%
          }
        
        .login-form h3 {
          font-size: 22px !important;
          text-align: left;
        }
        
        .form-group label {
          font-size: 0.9rem;
        }
        
        .form-group input {
          width: 100% !important;
          padding: 8px;
          font-size: 0.9rem;
        }
        
        .login-button {
          width: 50% !important;
          font-size: 0.9rem;
          transform: translate(20%);
        }
        
        .otp-login {
          font-size: 0.8rem;
          text-align: center;
        }
        
        .login-banner {
         order: -1;
         background-color: white;
         margin-bottom: 30px;
        }
        
        /* admin signup */
        .adminsignup-container {
            height: auto;
            border: none;
            box-shadow: none;
            flex-direction: column;
          }
        
          .adminsignup-banner {
            order: -1;
            width: 100%;
            padding: 20px;
            background-color: white;
          }
        
          .adminsignup-form {
            padding: 20px;
            width: 100%;
          }
        
          .adminsignup-form h3 {
            font-size: 22px !important;
            text-align: left;
          }

          /* Signup css */
          .signup-container {
            height: auto;
            border: none;
            box-shadow: none;
            flex-direction: column;
            align-items: center;
          }
        
          .signup-form {
            padding: 20px;
            width: 100%;
          }
        
          .signup-form h3 {
            font-size: 22px !important;
            text-align: left;
          }
        
          .form-group label {
            font-size: 0.9rem;
          }
        
          .form-group input {
            width: 100% !important;
            padding: 8px;
            font-size: 0.9rem;
          }
        
          .signup-button {
            width: 50% !important;
            font-size: 0.9rem;
            transform: translate(20%);
          }
        
          .signup-link {
            font-size: 0.8rem;
            text-align: center;
            margin-left: 0;
          }
        
          .signup-banner {
            order:-1;
            background-color: white;
            margin-bottom: 30px;
          }
    }
